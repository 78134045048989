<template lang="pug">
  .car-class-name
    template(v-if="carClass.sync")
      | {{ carClass.name }}
    .inactive(
      v-else
      :id="randomKey"
    )
      AppTooltip(
        :boundary="randomKey"
        :title="$t('inventory_management.inventory_for_future.warning.car_class_inactive')"
      )
        FaIcon.inactive-icon(
          icon="exclamation-triangle"
          @click="removeCarClass()"
        )
      | {{ carClass.name }} ({{ $t("shop_matching.inactive") }})
</template>

<script>
  // misc
  import { randomKey } from "@/helpers/common"

  export default {
    components: {
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    props: {
      carClass: {
        type: Object,
        default: () => new Object()
      }
    },

    computed: {
      randomKey
    },

    methods: {
      removeCarClass() {
        this.$emit("remove-car-class")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .car-class-name
    color: $default-black
    font-size: 0.8rem

    ::v-deep
      .app-tooltip
        margin-left: 0

    &.inactive
      color: $default-gray-medium

    .inactive-icon
      cursor: pointer
      color: $default-yellow
      margin-right: 5px
</style>
